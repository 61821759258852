import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import CompanyLogo from "./CompanyLogo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "company-logo"
    }}>{`Company Logo`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={CompanyLogo} mdxType="Props" />
    <h2 {...{
      "id": "default-basic-usage"
    }}>{`Default Basic Usage`}</h2>
    <Playground __position={1} __code={'<CompanyLogo image=\"access-auto-insurance.png\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CompanyLogo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CompanyLogo image="access-auto-insurance.png" mdxType="CompanyLogo" />
    </Playground>
    <h2 {...{
      "id": "custom-size-basic-usage"
    }}>{`Custom Size Basic Usage`}</h2>
    <Playground __position={2} __code={'<CompanyLogo image=\"Hartford.svg\" displayType=\"regular\" />\n<br />\n<CompanyLogo image=\"Hartford.svg\" displayType=\"banner\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CompanyLogo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CompanyLogo image="Hartford.svg" displayType="regular" mdxType="CompanyLogo" />
  <br />
  <CompanyLogo image="Hartford.svg" displayType="banner" mdxType="CompanyLogo" />
    </Playground>
    <h2 {...{
      "id": "custom-fill-color-basic-usage"
    }}>{`Custom Fill Color Basic Usage`}</h2>
    <Playground __position={3} __code={'<CompanyLogo image=\"Geico.png\" borderColor=\"red\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      CompanyLogo,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CompanyLogo image="Geico.png" borderColor="red" mdxType="CompanyLogo" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      